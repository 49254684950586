import React from 'react'
import MediaQuery from 'react-responsive'
import {graphql, StaticQuery} from 'gatsby'
import Img from 'gatsby-image'
import Flexbox from 'flexbox-react'
import {Container, Row, Col} from 'reactstrap'
import {getCurrentLangKey, getLangs, getUrlForLang} from 'ptz-i18n';
import {
    MdPhone,
    MdAccessTime,
    MdEmail,
    MdPlace
} from 'react-icons/md'

import {
    baseSpacing,
    mediumSpacing,
    backgroundLight,
    colorWhite,
    textDark,
    colorAccent,
} from '../theme'

import {
    Typography,
    ThemeLink
} from '../components'

import {Menu} from '../layouts'

function findItem(obj, key) {
    return obj.filter(o => o.key === key);
}

function getValue(obj, key) {
    const item = findItem(obj, key);
    return item[0].value;
}

const Header = ({isHome = false, en = false, ...props}) => {

    return (
        <StaticQuery
            query={graphql`
            {
              allWordpressGatsby {
                edges {
                  node {
                    id
                    key
                    value
                  }
                }
              },
            site {
              siteMetadata {
                title
                site_url
                subtitle
                languages {
                    defaultLangKey
                    langs
                }
              }
            },
              logo: file(relativePath: { eq: "images/logo.png" }) {
                  childImageSharp {
                      fluid(maxWidth: 323, maxHeight: 75){
                          ...GatsbyImageSharpFluid_withWebp_noBase64
                      }
                  }
              }
            }
            `}
            render={data => {
                const parseData = data.allWordpressGatsby.edges.map(item => item.node);
                const logo = data.logo;

                const url = props.location.pathname;
                const {langs, defaultLangKey} = data.site.siteMetadata.languages;
                const langKey = getCurrentLangKey(langs, defaultLangKey, url);
                const homeLink = `/${langKey}`.replace(`/${defaultLangKey}/`, '/');
                const langsMenu = getLangs(langs, langKey, getUrlForLang(homeLink, url)).map((item) => ({
                    ...item,
                    link: item.link.replace(`/${defaultLangKey}/`, '/')
                }));

                return (
                    <Flexbox flex={`1`} flexDirection={`column`}>
                        <Flexbox
                            css={{
                                padding: baseSpacing,
                                backgroundColor: backgroundLight,
                            }}>
                            <Container>
                                <Row>
                                    <Col xs={5} lg={7} xl={8} className={`d-flex align-items-center`}>
                                        {langsMenu && langsMenu.map((lang, key)=> {
                                            const el = `Ελληνικά`;
                                            const en = `English`;
                                            return (
                                                <ThemeLink
                                                    key={key}
                                                    url={lang.link}
                                                    css={lang.selected ? {fontWeight: `700`} : {}}
                                                >{lang.langKey === 'en' ? en : el}</ThemeLink>
                                            )
                                        })}
                                        <ThemeLink
                                            url={`tel:${getValue(parseData, 'phone')}`}
                                            color={textDark}
                                            className={`d-flex align-items-center`}
                                        >
                                            <MdPhone
                                                color={colorAccent}
                                                css={{
                                                    width: `1.5rem`,
                                                    height: `1.5rem`,
                                                    marginRight: baseSpacing
                                                }}
                                            />
                                            <Typography className={`d-none d-lg-block`}>{en ? `Call Us Now` : ``} {getValue(parseData, 'phone')}</Typography>
                                        </ThemeLink>
                                        <ThemeLink
                                            url={`mailto:${getValue(parseData, 'email')}`}
                                            color={textDark}
                                            className={`d-flex align-items-center`}
                                        >
                                            <MdEmail
                                                color={colorAccent}
                                                css={{
                                                    width: `1.5rem`,
                                                    height: `1.5rem`,
                                                    marginRight: baseSpacing
                                                }}
                                            />
                                            <Typography className={`d-none d-xl-block`}>{getValue(parseData, 'email')}</Typography>
                                        </ThemeLink>
                                        <ThemeLink
                                            url={`https://www.google.com/maps/place/Nazliou+57,+Paleo+Faliro+175+64/@37.9403882,23.704908,17z/data=!3m1!4b1!4m5!3m4!1s0x14a1bdff30df7bdb:0x9631e38864895330!8m2!3d37.9403882!4d23.7070967`}
                                            color={textDark}
                                            target={`_blank`}
                                            className={`d-flex align-items-center`}
                                        >
                                            <MdPlace
                                                color={colorAccent}
                                                css={{
                                                    width: `1.5rem`,
                                                    height: `1.5rem`,
                                                    marginRight: baseSpacing
                                                }}
                                            />
                                            <Typography className={`d-none d-xl-block`}>{getValue(parseData, 'address')}</Typography>
                                        </ThemeLink>
                                    </Col>
                                    <Col xs={7} lg={5} xl={4} className={`d-none d-md-flex justify-content-sm-end`}>
                                        <ThemeLink
                                            url={`#`}
                                            color={textDark}
                                            target={`_blank`}
                                            style={{marginRight: 0}}
                                            className={`d-flex align-items-center`}
                                        >
                                            <MdAccessTime
                                                color={colorAccent}
                                                css={{
                                                    width: `1.5rem`,
                                                    height: `1.5rem`,
                                                    marginRight: baseSpacing
                                                }}
                                            />
                                            <Typography>{getValue(parseData, 'topbar_text')}</Typography>
                                        </ThemeLink>
                                    </Col>
                                </Row>
                            </Container>
                        </Flexbox>
                        <Flexbox
                            css={{
                                padding: mediumSpacing,
                                backgroundColor: colorWhite,
                                borderBottom: `.1rem solid #ccc`
                            }}>
                            <Container>
                                <Row>
                                    <Col xs={9} sm={6} md={4} className={`d-flex justify-content-center justify-content-sm-start`}>
                                        <h1
                                            style={{
                                                width: `100%`,
                                                margin: 0
                                            }}
                                        >
                                            <ThemeLink
                                                url={`/${langKey}`.replace(`el`, '')}
                                                color={textDark}
                                                style={{
                                                    marginRight: 0
                                                }}
                                            >
                                                {logo ?
                                                    <Img fluid={logo.childImageSharp.fluid}
                                                         style={{
                                                             maxHeight: `75px`
                                                         }}
                                                         imgStyle={{
                                                             objectFit: `contain !important`,
                                                             maxWidth: `100%`,
                                                             width: `auto`,
                                                             maxHeight: `75px`
                                                         }}
                                                         alt='logo'
                                                    />
                                                    :
                                                    <Typography size={3} color={colorAccent}>
                                                        {getValue(parseData, 'site_name')}
                                                    </Typography>
                                                }
                                            </ThemeLink>
                                        </h1>
                                    </Col>
                                    <Col xs={3} sm={6} md={8} className={`d-flex align-items-center justify-content-end`}>
                                        <MediaQuery query="(max-width: 1500px)">
                                            <Menu isHome={isHome} en={en} mobile/>
                                        </MediaQuery>
                                        <MediaQuery query="(min-width: 1501px)">
                                            <Menu isHome={isHome} en={en}/>
                                        </MediaQuery>
                                    </Col>
                                </Row>
                            </Container>
                        </Flexbox>
                    </Flexbox>
                )
            }}
        />
    )
};
export default Header