import React from 'react';
import PropTypes from 'prop-types'
import Flexbox from 'flexbox-react'
import {Row, Col} from 'reactstrap'
import {
    Typography,
    SectionTitle
} from './index'

import {
    largeSpacing,
    backgroundLight,
    mediumSpacing,
    baseSpacing,
    colorAccent,
} from "../theme";

const SectionService = (props) => {
    const {
        sectionTitle,
        icon,
        title,
        description,
        children
    } = props;
    const regex = /(<([^>]+)>)/ig;
    const clearDescription = description.replace(regex, '');
    return (
        <>
            <Row
                className={`d-flex align-items-stretch`}
                style={{backgroundColor: backgroundLight, overflowY: `hidden`}}
                id={`services`}
                noGutters
            >
                <Col md={8} className={`pr-md-5`}>
                    <Flexbox
                        flexDirection={`column`}
                        css={{padding: `${mediumSpacing} ${largeSpacing}`}}
                    >
                        <Flexbox alignItems={`center`}>
                            <Typography
                                style={{
                                    padding: baseSpacing,
                                    borderRadius: `50%`,
                                    backgroundColor: `#D3E2E7`,
                                    marginRight: baseSpacing,
                                    'svg': {
                                        width: `2.5rem`,
                                        height: `2.5rem`,
                                        '& path': {
                                            fill: colorAccent
                                        }
                                    }
                                }}
                                gutterBottom
                                dangerouslySetInnerHTML={{__html: icon}}/>
                            <Typography
                                size={1.4}
                                style={{fontWeight: `600`}}
                                gutterBottom
                                dangerouslySetInnerHTML={{__html: title}}
                            />
                        </Flexbox>
                        <Typography dangerouslySetInnerHTML={{__html: clearDescription}}/>
                    </Flexbox>
                </Col>
                <Col md={4}>
                    <SectionTitle
                        title={sectionTitle}
                        style={{height: `100%`}}
                        arrowStyle={{borderTop: `16rem solid transparent`}}
                    />
                </Col>
            </Row>
            {children &&
            <Row noGutters>
                <Col xs={12}>
                    <Flexbox
                        flexDirection={`column`}
                    >
                        {children}
                    </Flexbox>
                </Col>
            </Row>
            }
        </>
    )
};

SectionService.propTypes = {
    sectionTitle: PropTypes.string.isRequired,
    icon: PropTypes.node.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
};

export default SectionService