export const colorPrimary = `#000000`;
export const colorSecondary = `#EDEDED`;
export const colorAccent = `#1E6A84`;
export const colorWhite = `#FFF`;

export const textLight = `#bfbfbf`;
export const textDark = `#000`;
export const backgroundLight = colorSecondary;
export const backgroundDark = `#000`;

export const S_MULTIPLIER = 1;
export const M_MULTIPLIER = 0.85;
export const L_MULTIPLIER = 1.2;
export const MIN_FONT = 1;
export const MAX_FONT = 1.35;


export const baseSpacing = `.5rem`;
export const mediumSpacing = `1rem`;
export const largeSpacing = `2rem`;

export const largeText = 2.5;

export const breakpoints = [420, 768, 1200];
export const MQ = breakpoints.map(bp => `@media screen and (min-width: ${bp}px)`);