import React from 'react'
import PropTypes from 'prop-types'
import {Link} from 'gatsby'
import {Typography} from './index'
import {colorAccent, mediumSpacing} from '../theme'

const ThemeLink = (props) => {
    const {
        url,
        color,
        hoverColor,
        size,
        children,
        style,
        className,
        noUnderline,
        target,
        local
    } = props;

    let styles = style;
    styles = noUnderline ? {...styles, ...{'&:hover': {textDecoration: `none`}}} : styles;

    let linkProps = {
        component: `a`,
        href: url
    };

    const rel = target === '_blank';

    const isLocal = url.charAt(0) === '/' || local;
    linkProps = isLocal ? {component: Link, to: url} : linkProps;
    linkProps = rel ? {...linkProps, ...{rel: `noreferrer`}} : linkProps;

    return (
        <Typography
            className={className}
            color={hoverColor ? hoverColor : color}
            size={size}
            target={target}
            {...linkProps}
            css={{
                marginRight: mediumSpacing,
                '&:hover': {
                    color: colorAccent,
                    textDecoration: `none`
                },
                ...styles,
            }}
        >{children}</Typography>
    )
};

ThemeLink.propTypes = {
    url: PropTypes.string.isRequired,
    color: PropTypes.string,
    size: PropTypes.string,
    style: PropTypes.object,
    children: PropTypes.any.isRequired,
};

export default ThemeLink