import React from 'react';
import PropTypes from 'prop-types'
import Flexbox from 'flexbox-react'
import {Typography} from './index'

import {
    colorWhite,
    mediumSpacing,
    largeSpacing,
    MQ
} from "../theme";

const SectionFeature = (props) => {
    const {
        title,
        icon
    } = props;

    return (
        <Flexbox
            alignItems={`center`}
            css={{marginBottom: largeSpacing}}
        >
            <Typography
                color={colorWhite}
                className={`d-flex align-items-center justify-content-center`}
                css={{
                    width: `4rem`,
                    height: `4rem`,
                    borderRadius: `50%`,
                    padding: mediumSpacing,
                    marginRight: largeSpacing,
                    backgroundColor: `#D3E2E7`
                }}
            >{icon}</Typography>
            <Typography
                size={1.4}
                style={{
                    lineHeight: `2.6rem`,
                    fontWeight: 300,
                    [MQ[2].replace('min-width', 'minWidth')]: {
                        maxWidth: `50%`
                    }
                }}
            >{title}</Typography>
        </Flexbox>
    )
};

SectionFeature.propTypes = {
    title: PropTypes.string.isRequired,
    icon: PropTypes.any.isRequired
};

export default SectionFeature