import React from 'react';
import PropTypes from 'prop-types'
import styled from '@emotion/styled'

const Image = styled.img({
    width: `100%`
});

const ResponsiveImage = (props) => {
    const {
        src,
        alt,
        style,
        cover,
        contain
    } = props;

    let styles = {...{height: `auto`}, ...style};

    styles = cover ? {...{objectFit: `cover`, height: `100%`}, ...styles} : styles;
    styles = contain ? {...{objectFit: `contain`}, ...styles} : styles;

    return (
        <Image
            src={src}
            alt={alt}
            css={{
                ...styles
            }}
        />
    )
};

ResponsiveImage.propTypes = {
    src: PropTypes.string.isRequired,
    alt: PropTypes.string.isRequired,
    style: PropTypes.object,
    contain: PropTypes.bool,
    cover: PropTypes.bool
};

export default ResponsiveImage