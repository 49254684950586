import React from 'react'
import PropTypes from 'prop-types'

import {
    mediumSpacing
} from '../theme';

const Typography = ({
                        component = `span`,
                        color,
                        size,
                        gutterBottom,
                        ...args
                    }) => {

    const {
        TagName = component,
        children,
        style
    } = args;

    let styles = {
        ...style
    };

    styles = color ? {...styles, ...{color: color}} : styles;
    styles = size ? {...styles, ...{fontSize: `${size}rem`, lineHeight: `${size}rem`}} : styles;
    styles = gutterBottom ? {...styles, ...{marginBottom: mediumSpacing}} : styles;

    return (
        <TagName
            css={{
                ...styles
            }}
            {...args}
        >
            {children}
        </TagName>
    )
};

Typography.propTypes = {
    component: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    color: PropTypes.string,
    size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    gutterBottom: PropTypes.bool,
    style: PropTypes.object,
    children: PropTypes.any,
};

export default Typography