import React from 'react';
import PropTypes from 'prop-types'
import Flexbox from 'flexbox-react'
import Logo from '../assets/footer-logo.png'
import {Container, Row, Col} from 'reactstrap'
import {
    Typography,
    ThemeLink,
    ResponsiveImage
} from '../components'

import {
    baseSpacing,
    mediumSpacing,
    backgroundDark,
    textLight
} from '../theme';

const Footer = (props) => {
    const {
        children,
        style
    } = props;

    return (
        <Flexbox flex={'1'} css={{
            ...style,
            padding: mediumSpacing,
            color: textLight,
            backgroundColor: backgroundDark
        }}>
            <Container>
                {children}
                <Row noGutters>
                    <ResponsiveImage
                        src={Logo}
                        alt={`footer logo`}
                        style={{
                            maxHeight: `15rem`
                        }}
                        contain
                    />
                </Row>
                <Row>
                    <Col md={6} className={`d-flex justify-content-center justify-content-md-start`}>
                        <Typography>2019 &copy; All Rights Reserved.</Typography>
                    </Col>
                    <Col md={6} className={`d-flex justify-content-center justify-content-md-end`}>
                        <Typography>Designed & Developed by</Typography>
                        <ThemeLink
                            url={`https://webo2.gr`}
                            color={`red`}
                            style={{
                                padding: `0 ${baseSpacing}`,
                                '&:hover': {
                                    color: `#cc0000`,
                                    textDecoration: `none`
                                }
                            }}
                        >
                            WebO2
                        </ThemeLink>
                    </Col>
                </Row>
            </Container>
        </Flexbox>
    )
};

Footer.propTypes = {
    style: PropTypes.object,
    children: PropTypes.node,
};

export default Footer