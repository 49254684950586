import React from 'react';
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import {Typography} from './index'

import {
    colorWhite,
    colorAccent,
    largeText,
    MQ,
    largeSpacing
} from "../theme";

const TitleArrow = styled.div({
    width: 0,
    height: `100%`,
    display: `block`,
    position: `absolute`,
    top: 0,
    bottom: 0,
    right: `100%`,
    borderTop: `8rem solid transparent`,
    borderRight: `8rem solid ${colorAccent}`
});

const SectionTitle = (props) => {
    const {
        title,
        style,
        onTop,
        arrowStyle
    } = props;

    let styles = style;

    const onTopStyles = {
        marginBottom: largeSpacing,
        [MQ[1]]: {
            position: `absolute`,
            left: 0,
            right: 0,
            zIndex: 2,
        }
    };

    styles = onTop ? {...onTopStyles, ...styles} : styles;

    return (
        <Typography
            size={largeText}
            color={colorWhite}
            target={`_blank`}
            className={`d-flex align-items-center justify-content-center`}
            css={{
                height: `8rem`,
                fontWeight: 300,
                textAlign: `center`,
                position: `relative`,
                padding: `3rem 0 3rem 0`,
                backgroundColor: colorAccent,
                ...styles,
            }}
        >
            <TitleArrow className={`d-none d-md-block`} css={{...arrowStyle}}/>
            {title}
        </Typography>
    )
};

SectionTitle.propTypes = {
    title: PropTypes.string.isRequired,
    style: PropTypes.object,
    arrowStyle: PropTypes.object,
};

export default SectionTitle