import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {graphql, StaticQuery} from 'gatsby'
import 'bootstrap/dist/css/bootstrap.css'
import Flexbox from 'flexbox-react'
import {Global, keyframes} from '@emotion/core'
import {Helmet} from 'react-helmet'
// import 'typeface-open-sans'
import {getCurrentLangKey} from 'ptz-i18n';
import {IntlProvider} from 'react-intl';
import 'intl';
import GoogleFontLoader from 'react-google-font-loader';
import '../assets/css/wpeditor.min.css'

import 'intl/locale-data/jsonp/en'
import 'intl/locale-data/jsonp/el'

import {FaSpinner} from 'react-icons/fa'
import {
    Container
} from 'reactstrap'

import {
    Header,
    Footer
} from './index'

import {
    textDark,
    breakpoints,
    MQ,
    S_MULTIPLIER,
    M_MULTIPLIER,
    L_MULTIPLIER,
    MIN_FONT,
    MAX_FONT, colorAccent, colorWhite
} from '../theme'

const loaderKeyframe = keyframes`
  to {
    transform: rotate(360deg)
  }
`;

const LoaderStyle = {
    animation: `${loaderKeyframe} 1.5s linear infinite`
};

class Layout extends Component {
    state = {
        isLoading: true
    };

    render() {
        const {
            data,
            full,
            isHome,
            children,
            location,
            i18nMessages,
            en
        } = this.props;

        const schemaOrgJSONLD = [
            {
                "@context": "http://schema.org",
                "@type": "WebSite",
                url: data.site.siteMetadata.site_url,
                name: data.site.siteMetadata.title,
                alternateName: data.site.siteMetadata.title
            }
        ];

        const isFullWidth = !!full;

        const url = location.pathname;
        const {langs, defaultLangKey} = data.site.siteMetadata.languages;
        const langKey = getCurrentLangKey(langs, defaultLangKey, url);

        // @TODO add canonical + site url from router
        return (
            <IntlProvider
                locale={langKey}
                messages={i18nMessages}
            >
                <Flexbox flexDirection={`column`}>
                    <GoogleFontLoader
                        fonts={[
                            {
                                font: 'Open Sans',
                                weights: [300, 400, 600, 700],
                            }
                        ]}
                        subsets={['cyrillic-ext', 'greek']}
                    />
                    {this.state.isLoading && <div
                        style={{
                            position: `fixed`,
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                            zIndex: 99,
                            display: `none`,
                            alignItems: `center`,
                            justifyContent: `center`,
                            backgroundColor: colorWhite
                        }}
                    >
                        <FaSpinner
                            size={`3rem`}
                            color={colorAccent}
                            css={{...LoaderStyle}}
                        />
                    </div>
                    }
                    <Helmet
                        htmlAttributes={{lang: en ? 'en' : 'el'}}
                        defer={false}
                    >
                        <meta charSet="utf-8"/>
                        <meta name='description' content={data.site.siteMetadata.subtitle}/>
                        <meta name='robots' content='all'/>
                        <meta name="viewport" content="width=device-width, initial-scale=1"/>
                        <title>{`${data.site.siteMetadata.title} - ${data.site.siteMetadata.subtitle}`}</title>
                        <link rel='canonical' href={data.site.siteMetadata.site_url}/>
                        <meta name='og:title' content={data.site.siteMetadata.title}/>
                        <meta name='og:type' content='website'/>
                        <meta name='og:description' content={data.site.siteMetadata.subtitle}/>
                        <meta name='og:url' content={data.site.siteMetadata.site_url}/>
                        <meta name='og:image' content={data.logo.childImageSharp.fixed.src}/>
                        <meta property="twitter:card" content={data.site.siteMetadata.subtitle}/>
                        <meta property="twitter:creator" content={data.site.siteMetadata.title}/>
                        <meta property="twitter:site" content={data.site.siteMetadata.title}/>
                        <script type="application/ld+json">
                            {JSON.stringify(schemaOrgJSONLD)}
                        </script>
                    </Helmet>
                    <Global
                        styles={{
                            'html': {
                                fontSize: `${MIN_FONT}rem`,
                                [MQ[1]]: {
                                    fontSize: `calc(${MIN_FONT * S_MULTIPLIER}rem + (${MAX_FONT * S_MULTIPLIER} - ${MIN_FONT * S_MULTIPLIER}) * (100vw - ${breakpoints[0]}px) / (${breakpoints[2]} - ${breakpoints[0]}))`,
                                },
                                [MQ[2]]: {
                                    fontSize: `calc(${MIN_FONT * M_MULTIPLIER}rem + (${MAX_FONT * M_MULTIPLIER} - ${MIN_FONT * M_MULTIPLIER}) * (100vw - ${breakpoints[0]}px) / (${breakpoints[2]} - ${breakpoints[0]}))`,
                                },
                                [MQ[3]]: {
                                    fontSize: `calc(${MIN_FONT * L_MULTIPLIER}rem + (${MAX_FONT * L_MULTIPLIER} - ${MIN_FONT * L_MULTIPLIER}) * (100vw - ${breakpoints[0]}px) / (${breakpoints[2]} - ${breakpoints[0]}))`,
                                }
                            },
                            'body': {
                                'fontFamily': `'Open Sans', sans-serif`,
                                'fontStyle': `normal`,
                                'lineHeight': `1.7rem`,
                                'fontSize': `1.15rem`,
                                'color': textDark,
                            },
                            'strong, b': {
                                'fontFamily': `'Open Sans', sans-serif`,
                                'fontWeight': '700',
                                'fontStyle': `normal`,
                            },
                            '.h1, h1': {
                                'fontFamily': `'Open Sans', sans-serif`,
                                'fontStyle': `normal`,
                                'fontSize': `2.375rem`,
                            },
                            '.h2, h2': {
                                'fontFamily': `'Open Sans', sans-serif`,
                                'fontStyle': `normal`,
                                'fontSize': `1.8125rem`,
                            },
                            '.h3, h3': {
                                'fontFamily': `'Open Sans', sans-serif`,
                                'fontStyle': `normal`,
                                'fontSize': `1.4375rem`,
                            },
                            '.h4, h4': {
                                'fontFamily': `'Open Sans', sans-serif`,
                                'fontStyle': `normal`,
                                'fontSize': '1rem',
                            },
                            '.container': {
                                maxWidth: `1560px`
                            }
                        }}
                    />
                    <Flexbox element={`header`} flex={`0 0 100%`}>
                        <Header isHome={isHome} en={en} location={location}/>
                    </Flexbox>
                    {!isFullWidth
                        ?
                        <Flexbox element={`main`} flex={`0 0 100%`}>
                            <Container>
                                {children}
                            </Container>
                        </Flexbox>
                        :
                        <Flexbox element={`main`} flex={`0 0 100%`} flexWrap={`wrap`}>
                            {children}
                        </Flexbox>
                    }
                    <Flexbox element={`footer`} flex={`0 0 100%`}>
                        <Footer/>
                    </Flexbox>
                </Flexbox>
            </IntlProvider>
        )
    }
}

Layout.propTypes = {
    full: PropTypes.bool,
    children: PropTypes.node,
};

export default props => (
    <StaticQuery
        query={graphql`
        query LayoutQuery {
            site {
              siteMetadata {
                title
                site_url
                subtitle
                languages {
                    defaultLangKey
                    langs
                }
              }
            }
            logo: file(relativePath: { eq: "favicon.png" }) {
              childImageSharp {
                  fixed(width: 512, height: 512){
                      ...GatsbyImageSharpFixed
                  }
              }
            }
        }
    `}
        render={data => <Layout {...props} data={data}/>}
    />
)