import React from 'react'
import styled from '@emotion/styled'
import {Link as PageLink} from 'gatsby'
import {Link} from 'react-scroll'
import {
    MdSend,
    MdMenu
} from 'react-icons/md'

import {
    baseSpacing,
    mediumSpacing,
    colorWhite,
    colorAccent,
    largeSpacing,
    colorSecondary, colorPrimary,
} from '../theme'

const MenuItemBase = {
    textTransform: `uppercase`,
    color: `${colorAccent} !important`,
    padding: `${mediumSpacing} ${largeSpacing}`,
    '&:hover': {
        cursor: `pointer`,
        textDecoration: `none`,
        backgroundColor: colorSecondary
    }
};

const MenuItem = styled(Link)({
    ...MenuItemBase
});

const ContactItem = styled(PageLink)({
    textTransform: `uppercase`,
    color: colorWhite,
    backgroundColor: colorAccent,
    padding: `${mediumSpacing} ${mediumSpacing}`,
    '&:hover': {
        cursor: `pointer`,
        color: colorWhite,
        textDecoration: `none`,
        opacity: `.9`
    }
});

const MobileSidebar = styled.div({
    position: `fixed`,
    left: 0,
    top: 0,
    bottom: 0,
    display: `flex`,
    flexDirection: `column`,
    paddingTop: mediumSpacing,
    backgroundColor: colorWhite,
    transition: `transform .3s ease`,
    maxWidth: `85vw`,
    zIndex: 6
});

const MenuToggleButton = styled.button({
    padding: baseSpacing,
    borderRadius: 0,
    border: `.07rem solid ${colorPrimary}`,
    backgroundColor: `initial`
});

class Menu extends React.Component {

    state = {
        isOpen: false
    };

    closeMenu = () => {
        if (this.props.mobile)
            this.setState({isOpen: !this.state.isOpen})
    };

    render() {
        const {isHome = false, mobile = false, en = false} = this.props;

        const {isOpen} = this.state;

        const TagName = mobile ? MobileSidebar : 'div';

        return (
            <>
                {mobile &&
                <MenuToggleButton onClick={this.closeMenu}>
                    <MdMenu
                        css={{
                            width: `1.5rem`,
                            height: `1.5rem`,
                        }}
                    />
                </MenuToggleButton>
                }
                {mobile && isOpen &&
                <div style={{
                    position: `fixed`,
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    backgroundColor: `rgba(0,0,0,.5)`,
                    zIndex: 5
                }}
                     onClick={this.closeMenu}
                />
                }
                <TagName css={
                    mobile ?
                        isOpen ? {
                            transform: `translateX(0)`
                        } : {
                            transform: `translateX(-100%)`
                        }
                        : {}
                }>
                    {isHome &&
                    <MenuItem
                        activeClass="active"
                        to={`services`}
                        spy={true}
                        smooth={true}
                        duration={800}
                        onClick={this.closeMenu}
                    >
                        {en ? `Services` : `Υπηρεσιες`}
                    </MenuItem>
                    }
                    {isHome &&
                    <MenuItem
                        activeClass="active"
                        to={`features`}
                        spy={true}
                        smooth={true}
                        duration={800}
                        onClick={this.closeMenu}
                    >
                        {en ? `Features` : `Χαρακτηριστικα`}
                    </MenuItem>
                    }
                    {isHome &&
                    <MenuItem
                        activeClass="active"
                        to={`about`}
                        spy={true}
                        smooth={true}
                        duration={800}
                        onClick={this.closeMenu}
                    >
                        {en ? `About Us` : `Σχετικα με εμας`}
                    </MenuItem>
                    }
                    {isHome &&
                    <MenuItem
                        activeClass="active"
                        to={`contact`}
                        spy={true}
                        smooth={true}
                        duration={800}
                    >
                        {en ? `Contact` : `Επικοινωνια`}
                    </MenuItem>
                    }
                    {!isHome &&
                    <PageLink
                        to={en ? `/en/` : `/`}
                        css={{
                            ...MenuItemBase
                        }}
                        onClick={this.closeMenu}
                    >
                        {en ? `Home` : `Αρχικη`}
                    </PageLink>
                    }
                    <ContactItem
                        to={en ? `/en/contact` : `/contact`}
                        onClick={this.closeMenu}
                        style={mobile ? {} : {
                            marginLeft: mediumSpacing
                        }}
                    >
                        <MdSend
                            css={{
                                width: `1.5rem`,
                                height: `1.5rem`,
                                marginRight: baseSpacing
                            }}
                        />
                        {en ? `Send us your request` : `Στειλτε μας το αιτημα σας`}
                    </ContactItem>
                </TagName>
            </>
        )
    }
}

export default Menu